<template>
  <div>
    <car-create-modal
      v-if="showSaveModal"
      :show="showSaveModal"
      :car="car"
      @close="saveModalClosed"
    />
    <NotSubscribedModal
      v-if="car"
      :show="showNotSubscribedModal"
      type="CAR_POOL"
      :can-use-car-pool="canUseCarPool"
      :has-additional-data="false"
      :car-id="car._id"
      @close="showNotSubscribedModal = false"
    />
    <ca-header heading="Fahrzeug zum Fahrzeugbestand hinzufügen">
      <template #alert>
        <ca-alert
          v-if="!!saveErrorMessage"
          variant="danger"
          :message="saveErrorMessage"
          @close="saveErrorMessage = null"
        />
        <ca-alert
          v-if="showFormInvalidHint"
          variant="info"
          message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
          @close="showFormInvalidHint = null"
        />
      </template>
      <template #buttonSpace>
        <button
          class="btn btn-success text-white"
          type="button"
          :disabled="savePending"
          @click="saveAndOpenModal()"
        >
          <span v-if="!savePending">Speichern</span>
          <i
            v-if="savePending"
            class="far fa-spin fa-circle-notch"
          />
        </button>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div class="row">
        <div class="col-12 col-xl-8 col-xxl-6">
          <car-form
            class="mb-3"
            :car="car"
          />

          <ca-card class="mb-3">
            <template #header>
              <h4 class="mb-0">
                Weitere Daten eingeben
              </h4>
            </template>
            <template #body>
              <div class="row">
                <div class="col-auto">
                  <i class="fal fa-file-alt fa-4x text-primary" />
                </div>
                <div class="col">
                  <p>
                    Hier haben Sie die Möglichkeit weitere Daten zu ihrem Fahrzeug eintragen um Ihr Fahrzeug zum Beispiel im Fahrzeugpool besser repräsentieren zu können.
                  </p>
                  <button
                    v-if="canUseCarPool"
                    class="btn btn-primary"
                    @click="saveAndNaviagateToAdditionalData"
                  >
                    <i class="far fa-plus" /> Weitere Daten hinzufügen
                  </button>
                  <button
                    v-else
                    class="btn btn-primary"
                    @click="showNotSubscribedModal = true"
                  >
                    <i class="far fa-plus" /> Weitere Daten hinzufügen
                  </button>
                </div>
              </div>
            </template>
          </ca-card>

          <dev-info :data="{car}" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaCard from '@/components/Card'
import DevInfo from '@/components/DevInfo'
import CaHeader from '@/components/Layout/Header'
import CarCreateModal from '@/components/Modals/CarCreateModal'
import CarForm from '@/components/Forms/Order/CarForm.vue'
import NotSubscribedModal from '@/components/Modals/NotSubscribedModal'
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  name: 'CarCreate',
  components: {
    DevInfo,
    CaHeader,
    CarCreateModal,
    NotSubscribedModal,
    CarForm,
    CaCard
  },
  data: () => ({
    showNotSubscribedModal: false,
    showSaveModal: false,
    savePending: false,
    saveError: null,
    loading: false,
    saveErrorMessage: null,
    showFormInvalidHint: false,
    car: {}
  }),
  computed: {
    canUseCarPool () {
      return this.me.subscriptions.reduce((prev, cur) => {
        if (prev === true) return true
        if (new Date(cur.from) <= Date.now() && new Date(cur.to) >= Date.now() && cur.type === 'CAR_POOL') return true
        return false
      }, false)
    },
    me () {
      return this.$store.getters['auth/user']
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    saveModalClosed () {
      // zum Fahrzeug
      this.$router.push({ name: 'CarDetail', params: { carId: this.car._id } })
    },
    async saveCar () {
      try {
        const valid = await this.$validator.validateAll()
        if (this.savePending) {
          return
        }
        if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        this.saveErrorMessage = null
        this.showFormInvalidHint = false
        this.savePending = true
        return this.$store.dispatch('car/create', this.car)
      } catch (error) {
        console.error(error)
        this.saveError = error
      } finally {
        this.savePending = false
      }
    },
    async saveAndOpenModal () {
      const savedCar = await this.saveCar()
      if (savedCar) {
        this.car = savedCar
        this.showSaveModal = true
      }
    },
    async saveAndNaviagateToAdditionalData () {
      const savedCar = await this.saveCar()
      if (savedCar) {
        this.$router.push({ name: 'CarAdditionalData', params: { carId: savedCar._id } })
      }
    },
    async fetchData () {
      try {
        this.loading = true
        this.car = new this.$FeathersVuex.api.Car()
      } catch (error) {
        this.saveErrorMessage = error
        console.error(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
